import * as React from "react"
import { Heading, Paragraph } from "grommet"
import Layout from "../components/layout"

const IndexPage = () => {
	return (
		<Layout pageTitle="Home">
			<Heading>Home</Heading>
			<Paragraph>Unnamed Auto Detailing - Coming soon!</Paragraph>
		</Layout>
	)
}

export default IndexPage
